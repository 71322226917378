var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vbt-dialog',{attrs:{"title":"Test order item","hide-submit-btn":"","width":"80%"},on:{"close":_vm.clearForm},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","color":"warning"}},on),[_vm._v(" "+_vm._s(_vm.runBtnText)+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-layout',{attrs:{"align-center":""}},[_c('v-flex',{attrs:{"sm4":""}},[_c('v-text-field',{attrs:{"label":"Order ID"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.runTest($event)}},model:{value:(_vm.optionOrderId),callback:function ($$v) {_vm.optionOrderId=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"optionOrderId"}})],1),_c('v-btn',{staticClass:"ml-4",attrs:{"disabled":!_vm.optionOrderId,"color":"success","small":""},on:{"click":_vm.runTest}},[_vm._v(" Run test ")])],1),(_vm.testResult.length)?[_c('strong',[_vm._v(" Test result: ")]),_c('v-divider'),_c('div',{staticClass:"result-wrapper"},_vm._l((_vm.testResult),function(res){return _c('v-layout',{key:_vm.genGUID(res),staticClass:"my-4"},[_c('v-flex',{attrs:{"sm5":""}},[_c('v-layout',{attrs:{"column":""}},[_c('v-layout',[_c('strong',{staticStyle:{"width":"90px"}},[_vm._v(" Order Item: ")]),_c('router-link',{attrs:{"to":{
                  name: 'orderItem',
                  params: {
                    id: res.orderItemId,
                  },
                  query: {
                    suffix: _vm.suffix,
                  },
                },"target":"_blank"}},[_vm._v(" "+_vm._s(res.profitId)+" ")])],1),(res.description)?_c('v-layout',[_c('strong',{staticStyle:{"width":"90px"}},[_vm._v(" Description: ")]),_vm._v(" "+_vm._s(res.description)+" ")]):_vm._e()],1)],1),((res.signatureResults || []).length)?[_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('v-flex',{attrs:{"sm7":""}},[_c('vbt-table',{attrs:{"headers":_vm.signatureHeaders,"items":res.signatureResults,"dense":""},scopedSlots:_vm._u([{key:"filterId",fn:function(ref){
                var filterId = ref.item.filterId;
return [_c('router-link',{attrs:{"to":{
                    name: 'ruleDetails',
                    params: {
                      id: filterId,
                    },
                    query: {
                      suffix: _vm.suffix,
                    },
                  },"target":"_blank"}},[_vm._v(" "+_vm._s(filterId)+" ")])]}}],null,true)})],1)]:_vm._e()],2)}),1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }