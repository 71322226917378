import { mapState, mapActions, mapGetters } from 'vuex';

import { useDataLoading, usePagination, useSearch } from '@mixins/factories';

import { formatDisplayDate, getDatesDifference, wrapToLoadingFn } from '@helpers';

import {
  AuthNamespace,
  AutocompleteNamespace,
  AutocompleteActionTypes,
  EnumsNamespace,
  AppLocationsNamespace,
} from '@store/types';

import { namespace as OrderItemsNamespace, ActionTypes as OrderItemsActionTypes } from '../../store.types';

import { OrderItemsApiService } from '../../_services/orderItems.api.service';

import DsbOrderItemsSync from '../../_components/DsbOrderItemsSync.vue';
import DsbOrderItemTest from '../../_components/DsbOrderItemTest.vue';
import DsbOrderItemIdViewer from '../../_components/DsbOrderItemIdViewer.vue';

const dataLoadingOptions = {
  getterName: OrderItemsActionTypes.GET_ORDER_ITEMS,
  contentName: 'orderItems',
};
const paginationOptions = { contentName: 'orderItems' };
const searchOptions = {
  initialSearchDataFields: [
    'itemId', 'orderId', 'tags', 'state', 'productType',
  ],
  customTypes: {
    tags: 'array',
  },
};

const headers = Object.freeze([
  { text: 'ID', value: 'id' },
  { text: 'Order ID', value: 'orderId' },
  { text: 'Order Item ID', value: 'orderItemId', width: '150px' },
  { text: 'Product type', value: 'productType' },
  { text: 'Truck Date', value: 'truckDate', width: '180px' },
  { text: 'Last Action Date', value: 'processed', width: '180px' },
  { text: 'Contract', value: 'contract' },
  { text: 'State', value: 'state' },
  { text: 'Details', value: 'details' },
  { text: '', value: 'actions', width: '80px' },
]);

export default {
  name: 'DsbOrderItems',

  components: {
    DsbOrderItemsSync,
    DsbOrderItemTest,
    DsbOrderItemIdViewer,
  },

  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
    useSearch(searchOptions),
  ],

  data() {
    return {
      tags: [],

      testOrderId: '',
      testDialog: false,

      headers,
    };
  },

  computed: {
    ...mapState(OrderItemsNamespace, ['orderItems']),
    ...mapState(AutocompleteNamespace, ['productAutocomplete']),
    ...mapState(AppLocationsNamespace, ['suffix']),

    ...mapGetters(EnumsNamespace, ['orderItemsStates']),
    ...mapGetters(AuthNamespace, ['hadAdminRole']),

    searchFieldsConfig() {
      return [
        {
          value: 'orderId',
          label: 'Order ID',
          type: 'text',
          flex: 'sm6',
        },
        {
          value: 'itemId',
          label: 'Order Item ID',
          type: 'text',
          flex: 'sm6',
        },
        {
          value: 'tags',
          label: 'Tags',
          items: this.tags,
          type: 'multiselect',
          flex: 'tags-search-field sm4',
        },
        {
          value: 'state',
          label: 'State',
          items: this.orderItemsStates,
          type: 'select',
          flex: 'sm4',
        },
        {
          value: 'productType',
          label: 'Product Type',
          type: 'autocomplete',
          placeholder: 'Start typing...',
          items: this.productAutocomplete,
          callback: this.getProductAutocomplete,
          flex: 'sm4',
        },
      ];
    },
  },

  watch: {
    suffix: {
      handler(suffix) {
        if (suffix) {
          this.getTags(suffix);
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions(OrderItemsNamespace, [OrderItemsActionTypes.GET_ORDER_ITEMS]),

    ...mapActions(AutocompleteNamespace, {
      getProductAutocomplete: (dispatch, query) => dispatch(
        AutocompleteActionTypes.GET_PRODUCT_AUTOCOMPLETE,
        { query },
      ),
    }),

    isTruckDateInFuture(truckDate) {
      return (new Date(truckDate) - new Date()) > 0;
    },

    runTestForOrder(orderId) {
      this.testOrderId = orderId;
      this.testDialog = true;
    },

    resetTestOrderId() {
      this.testOrderId = '';
    },

    getTags(suffix) {
      this.wrapToLoadingFn({
        req: OrderItemsApiService.getTags.bind({}, { suffix }),
        onSuccess: (r) => {
          this.tags = r;
        },
        onReject: () => {},
      });
    },

    formatDisplayDate,
    getDatesDifference,
    wrapToLoadingFn,
  },
};
