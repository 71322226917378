<template>
  <v-layout
    :id="widgetId"
    align-center
  >
    <v-tooltip top>
      <template #activator="{ on }">
        <span
          style="cursor: pointer;"
          v-on="on"
        >
          <span @click="copyOrderItemId">
            {{ formattedOrderItemId }}
          </span>
        </span>
      </template>
      {{ orderItemId }}
    </v-tooltip>

    <v-tooltip top>
      <template #activator="{ on }">
        <span v-on="on">
          <v-btn
            icon
            x-small
            @mouseenter="onEnterCopyBtn"
            @mouseleave="onLeaveCopyBtn"
            @click="copyOrderItemId"
          >
            <v-icon x-small>
              content_copy
            </v-icon>
          </v-btn>
        </span>
      </template>
      {{ isCopied ? 'Successfully copied' : 'Click to copy' }}
    </v-tooltip>
  </v-layout>
</template>

<script>
import { copyToClipboard, genGUID } from '@helpers';

export default {
  name: 'DsbOrderItemIdViewer',

  props: {
    orderItemId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      widgetId: genGUID(),

      isOverCopyBtn: false,
      isCopied: false,
    };
  },

  computed: {
    formattedOrderItemId() {
      return this.withShort ? `${this.orderItemId.slice(0, 5)}...` : this.orderItemId;
    },

    withShort() {
      return this.orderItemId.length > 5;
    },
  },

  methods: {
    copyOrderItemId() {
      copyToClipboard(this.orderItemId, this.widgetId);
      this.isCopied = true;
    },

    onEnterCopyBtn() {
      this.isOverCopyBtn = true;
      this.isCopied = false;
    },

    onLeaveCopyBtn() {
      setTimeout(() => {
        this.isOverCopyBtn = false;
        this.isCopied = false;
      }, 80);
    },
  },
};
</script>
