<template>
  <vbt-dialog
    v-model="dialog"
    title="Test order item"
    hide-submit-btn
    width="80%"
    @close="clearForm"
  >
    <template #activator="{ on }">
      <v-btn
        small
        color="warning"
        v-on="on"
      >
        {{ runBtnText }}
      </v-btn>
    </template>

    <v-layout align-center>
      <v-flex sm4>
        <v-text-field
          v-model.trim="optionOrderId"
          label="Order ID"
          @keypress.enter="runTest"
        />
      </v-flex>

      <v-btn
        :disabled="!optionOrderId"
        color="success"
        class="ml-4"
        small
        @click="runTest"
      >
        Run test
      </v-btn>
    </v-layout>

    <template v-if="testResult.length">
      <strong>
        Test result:
      </strong>
      <v-divider />

      <div class="result-wrapper">
        <v-layout
          v-for="res in testResult"
          :key="genGUID(res)"
          class="my-4"
        >
          <v-flex sm5>
            <v-layout column>
              <v-layout>
                <strong style="width: 90px;">
                  Order Item:
                </strong>
                <router-link
                  :to="{
                    name: 'orderItem',
                    params: {
                      id: res.orderItemId,
                    },
                    query: {
                      suffix,
                    },
                  }"
                  target="_blank"
                >
                  {{ res.profitId }}
                </router-link>
              </v-layout>

              <v-layout v-if="res.description">
                <strong style="width: 90px;">
                  Description:
                </strong>
                {{ res.description }}
              </v-layout>
            </v-layout>
          </v-flex>

          <template v-if="(res.signatureResults || []).length">
            <v-divider
              class="mx-4"
              vertical
            />

            <v-flex sm7>
              <vbt-table
                :headers="signatureHeaders"
                :items="res.signatureResults"
                dense
              >
                <template #filterId="{ item: { filterId } }">
                  <router-link
                    :to="{
                      name: 'ruleDetails',
                      params: {
                        id: filterId,
                      },
                      query: {
                        suffix,
                      },
                    }"
                    target="_blank"
                  >
                    {{ filterId }}
                  </router-link>
                </template>
              </vbt-table>
            </v-flex>
          </template>
        </v-layout>
      </div>
    </template>
  </vbt-dialog>
</template>

<script>
import { mapState } from 'vuex';

import { useDialog } from '@mixins/factories';

import { genGUID, wrapToLoadingFn } from '@helpers';

import { AppLocationsNamespace } from '@store/types';

import { OrderItemsApiService } from '../_services/orderItems.api.service';

const signatureHeaders = Object.freeze([
  { text: 'Signature Number', value: 'signatureNumber', width: '170px' },
  { text: 'Rule', value: 'filterId' },
  { text: 'Description', value: 'description' },
]);

export default {
  name: 'DsbOrderItemTest',

  props: {
    runBtnText: {
      type: String,
      default: 'Test order item',
    },
    orderId: {
      type: String,
      default: '',
    },
  },

  mixins: [
    useDialog(),
  ],

  data() {
    return {
      optionOrderId: '',
      testResult: [],

      signatureHeaders,
    };
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),
  },

  watch: {
    orderId: {
      handler(newValue) {
        this.optionOrderId = newValue || this.optionOrderId;
      },
      immediate: true,
    },
    dialog(newValue) {
      if (newValue && this.orderId) {
        this.runTest();
      }
    },
  },

  methods: {
    runTest() {
      if (!this.optionOrderId) {
        return;
      }

      this.testResult = [];

      this.wrapToLoadingFn({
        req: OrderItemsApiService.testOrderItem.bind({}, {
          suffix: this.suffix,
          orderId: this.optionOrderId,
        }),
        fullscreenLoadingSpinner: true,
        onSuccess: (result) => {
          if (result.length) {
            this.testResult = result;
          } else {
            this.$VBlackerTheme.notification.warning(`No test result for order ${this.optionOrderId}`);
          }
        },
      });
    },

    clearForm() {
      this.optionOrderId = '';
      this.testResult = [];

      this.$emit('close');
    },

    genGUID,
    wrapToLoadingFn,
  },
};
</script>

<style lang="css" scoped>
.result-wrapper {
  max-height: 400px;
  overflow-y: auto;
}
</style>
