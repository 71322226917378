import { render, staticRenderFns } from "./DsbOrderItemTest.vue?vue&type=template&id=abb7e1ae&scoped=true&"
import script from "./DsbOrderItemTest.vue?vue&type=script&lang=js&"
export * from "./DsbOrderItemTest.vue?vue&type=script&lang=js&"
import style0 from "./DsbOrderItemTest.vue?vue&type=style&index=0&id=abb7e1ae&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abb7e1ae",
  null
  
)

export default component.exports